import ParamsBuilder from '../../../helpers/paramsBuilder';
import EntityAdapter from '../../../helpers/entityAdapter';

export const fields = {
	id                  : 'id',
	customerID          : 'customer_id',
	email               : 'email',
	userName            : 'username',
	firstName           : 'first_name',
	lastName            : 'last_name',
	genderID            : 'gender',
	created             : 'created',
	balance             : 'balance',
	currencyID          : 'currency_id',
	currencyCode        : 'currency_code',
	statusID            : 'status_id',
	langID              : 'language_id',
	countryID           : 'country',
	zipCode             : 'zip_code',
	city                : 'city',
	address_1           : 'address_1',
	address_2           : 'address_2',
	phone               : 'phone',
	birth               : 'birth',
	timeZone            : 'time_zone',
	utm                 : 'utm',
	nationalID          : 'national_id',
	gamingStatusID      : 'gaming_status',
	oddsFormatID        : 'odds_format',
	referrer            : 'referrer',
	state               : 'state',
	stateID             : 'state_id',
	stateIDissueDate    : 'state_id_issue_date',
	isVerified          : 'is_verified',
	isOnline            : 'is_online',
	emailContact        : 'email_contact',
	phoneContact        : 'phone_contact',
	receiveNewsletters  : 'receive_newsletters',
	description         : 'description',
	avatarURL           : 'avatar',
	verificationPhotoURL: 'verification_photo',
	verificationStatusID: 'verification_status',
	profitabilityID     : 'profitability',
	riskGroup           : 'risk_group_id',
	riskGroupName       : 'risk_group_name',
	age                 : 'age',
	affiliateReference  : 'affiliate_reference',
	facebookID          : 'facebook_id',
	linkedID            : 'linkedin_id',
	googleID            : 'google_id',
	vkID                : 'vk_id',
	okID                : 'ok_id',
	mailruID            : 'mailru_id',
	emailVerified       : 'email_verified',
	ageVerified         : 'age_verified',
	phoneVerified       : 'phone_verified',
	creditCardVerified  : 'credit_card_verified',
	passportVerified    : 'passport_verified',
	domain              : 'domain',
	betsAmount          : 'bets_amount',
	lastBetAmount       : 'last_bet_amount',
	depositsAmount      : 'deposits_amount',
	withdrawalsAmount   : 'withdrawal_amount',
	minStake            : 'min_stake',
	maxStake            : 'max_stake',
	lastLoginDate       : 'last_login_date',
	lastBetDate         : 'last_bet_date',
	averageStake        : 'average_stake',
	turnover            : 'turnover',
	depositsCount       : 'deposits_count',
	withdrawalsCount    : 'withdrawal_count',
	betsCount           : 'bets_count',
	documentNumber      : 'documnent_number',
	firstDeposit        : 'first_deposit_date',
	firstWithdrawal     : 'first_withdrawal_date',
	registrationIP      : 'registration_ip',
	GGR                 : 'ggr',
};

const listAdapter = createListAdapter();

// Params -----------------------------------------------------------------------------------------

export function getListParams(filter, sorting = null, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;
	if (sorting) {
		const sortingField = sorting.sortBy === 'riskGroup' ? fields.riskGroupName : fields[sorting.sortBy];
		builder.addValue('sort_by', sortingField);
		builder.addValue('sort_order', sorting.sortOrder);
	}

	if (pagination) {
		builder.addValue('page', pagination.currentPage);
		builder.addValue('limit', pagination.itemsPerPage);
	}

	builder.addField(rules.isID, 'userID', 'id');
	builder.addField(rules.isString, 'customerIP', 'customer_ip');
	builder.addField(rules.isID, 'statusID', 'status_id');
	builder.addField(rules.isID, 'gamingStatusID', 'gaming_status');
	builder.addField(rules.isID, 'verificationStatusID', 'verification_status');
	builder.addField(rules.isID, 'genderID', 'sex');
	builder.addField(rules.isID, 'profitabilityGradeID', 'profitability');

	// builder.addField(rules.isString, 'riskGroupID', 'risk_group_id');
	builder.addField(rules.isString, 'riskGroupName', 'risk_group_name');
	builder.addField(rules.isString, 'userName', 'username');
	builder.addField(rules.isString, 'userEmail', 'email');
	builder.addField(rules.isString, 'registrationIP', 'registration_ip');
	builder.addField(rules.isString, 'userFirstName', 'first_name');
	builder.addField(rules.isString, 'userLastName', 'last_name');
	builder.addField(rules.isString, 'domain', 'domain');
	builder.addField(rules.isString, 'documentNumber', 'document_number');
	builder.addField(rules.isString, 'country', 'country');
	builder.addField(rules.isString, 'registrationCountry', 'country_of_registration');
	builder.addField(rules.isString, 'affiliateReference', 'affiliate_reference');
	builder.addField(rules.isString, 'customerID', 'customer_id');
	builder.addField(rules.isString, 'nationalID', 'national_id');
	builder.addField(rules.isArrayID, 'currency', 'currency_ids');

	builder.addField(rules.isArrayID, 'validationStatus', 'validation_status');
	builder.addField(rules.isArrayID, 'riskGroup', 'risk_group');
	builder.addField(rules.isString, 'agentID', 'agent_id');

	builder.addRangeField(rules.isDateTimeRange, filter.regDateRange, ['date_from', 'date_to']);
	builder.addRangeField(rules.isDateTimeRange, filter.birthday, ['birth_date_from', 'birth_date_to']);
	builder.addRangeField(rules.isDateTimeRange, filter.firstDeposit, ['first_deposit_date_from', 'first_deposit_date_to']);
	builder.addRangeField(rules.isDateTimeRange, filter.firstWithdrawal, ['first_withdrawal_date_from', 'first_withdrawal_date_to']);
	builder.addRangeField(rules.isDateTimeRange, filter.lastBetDate, ['last_bet_date_from', 'last_bet_date_to']);
	builder.addRangeField(rules.isDateTimeRange, filter.lastLoginDate, ['last_login_date_from', 'last_login_date_to']);

	builder.addRangeField(rules.isNumberRange, filter.age, ['age_from', 'age_to']);
	builder.addRangeField(rules.isNumberRange, filter.depositsRange, ['deposits_range_from', 'deposits_range_to']);
	builder.addRangeField(rules.isNumberRange, filter.withdrawalsRange, ['withdrawal_range_from', 'withdrawal_range_to']);
	builder.addRangeField(rules.isNumberRange, filter.withdrawalsAmount, ['withdrawal_amount_from', 'withdrawal_amount_to']);
	builder.addRangeField(rules.isNumberRange, filter.depositsAmount, ['deposits_amount_from', 'deposits_amount_to']);
	builder.addRangeField(rules.isNumberRange, filter.betsCount, ['bets_count_from', 'bets_count_to']);
	builder.addRangeField(rules.isNumberRange, filter.lastBetAmount, ['last_bet_amount_from', 'last_bet_amount_to']);
	builder.addRangeField(rules.isNumberRange, filter.betsAmount, ['bets_amount_from', 'bets_amount_to']);
	builder.addRangeField(rules.isNumberRange, filter.minStake, ['min_stake_from', 'min_stake_to']);
	builder.addRangeField(rules.isNumberRange, filter.maxStake, ['max_stake_from', 'max_stake_to']);
	builder.addField(rules.number, 'GGR', fields.GGR);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapting ---------------------------------------------------------------------------------------

export function adaptUsersList(rawUsersList = []) {

	listAdapter.clearExcludes();
	const adaptedList = listAdapter.adaptList(rawUsersList);

	return adaptedList;
}

// Adapters ---------------------------------------------------------------------------------------

function createListAdapter() {

	const adapter = new EntityAdapter();
	const rules   = adapter.RULES;

	adapter.addField(rules.id, 'id', fields.id);
	adapter.addField(rules.id, 'genderID', fields.genderID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'langID', fields.langID);
	adapter.addField(rules.id, 'gamingStatusID', fields.gamingStatusID);
	adapter.addField(rules.id, 'verificationStatusID', fields.verificationStatusID);
	adapter.addField(rules.id, 'profitabilityID', fields.profitabilityID);
	adapter.addField(rules.id, 'oddsFormatID', fields.oddsFormatID);
	adapter.addField(rules.id, 'facebookID', fields.facebookID);
	adapter.addField(rules.stringOrNull, 'linkedID', fields.linkedID);
	adapter.addField(rules.id, 'googleID', fields.googleID);
	adapter.addField(rules.id, 'vkID', fields.vkID);
	adapter.addField(rules.id, 'okID', fields.okID);
	adapter.addField(rules.id, 'mailruID', fields.mailruID);

	adapter.addField(rules.number, 'riskGroup', fields.riskGroup);
	adapter.addField(rules.number, 'age', fields.age);
	adapter.addField(rules.number, 'betsAmount', fields.betsAmount);
	adapter.addField(rules.number, 'lastBetAmount', fields.lastBetAmount);
	adapter.addField(rules.number, 'depositsAmount', fields.depositsAmount);
	adapter.addField(rules.number, 'withdrawalsAmount', fields.withdrawalsAmount);
	adapter.addField(rules.number, 'minStake', fields.minStake);
	adapter.addField(rules.number, 'maxStake', fields.maxStake);
	adapter.addField(rules.number, 'averageStake', fields.averageStake);
	adapter.addField(rules.number, 'turnover', fields.turnover);
	adapter.addField(rules.number, 'depositsCount', fields.depositsCount);
	adapter.addField(rules.number, 'withdrawalsCount', fields.withdrawalsCount);
	adapter.addField(rules.number, 'betsCount', fields.betsCount);

	adapter.addField(rules.string, 'nationalID', fields.nationalID);
	adapter.addField(rules.string, 'customerID', fields.customerID);
	adapter.addField(rules.string, 'email', fields.email);
	adapter.addField(rules.string, 'userName', fields.userName);
	adapter.addField(rules.string, 'firstName', fields.firstName);
	adapter.addField(rules.string, 'lastName', fields.lastName);
	adapter.addField(rules.string, 'currencyCode', fields.currencyCode);
	adapter.addField(rules.string, 'countryID', fields.countryID);
	adapter.addField(rules.string, 'zipCode', fields.zipCode);
	adapter.addField(rules.string, 'city', fields.city);
	adapter.addField(rules.string, 'address_1', fields.address_1);
	adapter.addField(rules.string, 'address_2', fields.address_2);
	adapter.addField(rules.string, 'phone', fields.phone);
	adapter.addField(rules.string, 'timeZone', fields.timeZone);
	adapter.addField(rules.string, 'utm', fields.customerID);
	adapter.addField(rules.string, 'referrer', fields.referrer);
	adapter.addField(rules.string, 'state', fields.state);
	adapter.addField(rules.string, 'stateID', fields.stateID);
	adapter.addField(rules.string, 'description', fields.description);
	adapter.addField(rules.string, 'avatarURL', fields.avatarURL);
	adapter.addField(rules.string, 'verificationPhotoURL', fields.verificationPhotoURL);
	adapter.addField(rules.string, 'riskGroupName', fields.riskGroupName);
	adapter.addField(rules.string, 'affiliateReference', fields.affiliateReference);
	adapter.addField(rules.string, 'domain', fields.domain);
	adapter.addField(rules.string, 'documentNumber', fields.documentNumber);
	adapter.addField(rules.string, 'registrationIP', fields.registrationIP);

	adapter.addField(rules.fullDate, 'created', fields.created);
	adapter.addField(rules.date, 'birth', fields.birth);
	adapter.addField(rules.fullDate, 'stateIDissueDate', fields.stateIDissueDate);
	adapter.addField(rules.fullDate, 'lastLoginDate', fields.lastLoginDate);
	adapter.addField(rules.fullDate, 'lastBetDate', fields.lastBetDate);
	adapter.addField(rules.fullDate, 'firstDeposit', fields.firstDeposit);
	adapter.addField(rules.fullDate, 'firstWithdrawal', fields.firstWithdrawal);

	adapter.addField(rules.bool, 'isVerified', fields.isVerified);
	adapter.addField(rules.bool, 'isOnline', fields.isOnline);
	adapter.addField(rules.bool, 'emailContact', fields.emailContact);
	adapter.addField(rules.bool, 'phoneContact', fields.phoneContact);
	adapter.addField(rules.bool, 'receiveNewsletters', fields.receiveNewsletters);
	adapter.addField(rules.bool, 'emailVerified', fields.emailVerified);
	adapter.addField(rules.bool, 'ageVerified', fields.ageVerified);
	adapter.addField(rules.bool, 'phoneVerified', fields.phoneVerified);
	adapter.addField(rules.bool, 'creditCardVerified', fields.creditCardVerified);
	adapter.addField(rules.bool, 'passportVerified', fields.passportVerified);

	adapter.addField(rules.number, 'balance', fields.balance);
	adapter.addField(rules.number, 'GGR', fields.GGR);

	return adapter;
}

import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import { fields, getListParams } from '../list/utils';

export const availableFields = [
	'id',
	'email',
	'userName',
	'firstName',
	'lastName',
	'genderID',
	'created',
	'balance',
	'currencyID',
	'statusID',
	'langID',
	'countryID',
	'zipCode',
	'city',
	'address_1',
	'address_2',
	'phone',
	'birth',
	'timeZone',
	'utm',
	'gamingStatusID',
	'oddsFormatID',
	'referrer',
	'state',
	'stateID',
	'stateIDissueDate',
	'verificationStatusID',
	'isOnline',
	'emailContact',
	'phoneContact',
	'receiveNewsletters',
	'description',
	'avatarURL',
	'verificationPhotoURL',
	'profitabilityID',
	'affiliateReference',
	'facebookID',
	'linkedID',
	'googleID',
	'vkID',
	'okID',
	'mailruID',
	'emailVerified',
	'ageVerified',
	'phoneVerified',
	'creditCardVerified',
	'passportVerified',
	'domain',
	'depositsCount',
	'withdrawalsCount',
	'betsCount',
	'customerID',
	'GGR',
];

export function createReportParams(filter, sorting, reportFields) {
	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields);

	return params;
}

// Service ----------------------------------------------------------------------------------------

function getReportFields(reportFields) {

	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}

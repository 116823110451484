import ParamsBuilder from '../../../../../helpers/paramsBuilder';
import EntityAdapter from '../../../../../helpers/entityAdapter';
import { createEntities } from '../../../../../helpers/utils';
import { IInitialFilter, IResTransactionAdapt, ITransactionsData } from './types';
import { isArray, isEmpty } from 'lodash';

export const availableFields = [
	'ID',
	'typeID',
	'statusID',
	'currencyID',
	'externalReferenceNumber',
	'moneyTypeID',
	'productID',
	'balanceBefore',
	'balanceAfter',
	'ip',
	'ipCountry',
	'code',
	'bonusType',
	'bonusEligibility',
	'bonusAmount',
	'totalBonus',
	'amount',
	'amountUSD',
	'created',
	'data',
];

export const fields = {
	ID                     : 'id',
	typeID                 : 'type_id',
	statusID               : 'status_id',
	externalReferenceNumber: 'external_reference_number', //string
	userID                 : 'user_id',
	moneyTypeID            : 'money_type_id',
	amount                 : 'amount',
	amountUSD              : 'amount_usd',
	currencyID             : 'currency_id',
	productID              : 'product_id',
	data                   : 'data', // string
	balanceBefore          : 'balance_before',
	balanceAfter           : 'balance_after',

	channelID: 'channel_id',
	ip       : 'ip', // string
	ipCountry: 'ip_country', // string
	sourceID : 'source_id',
	code     : 'code', // string

	sourceTypeID    : 'source_type_id',
	bonusType       : 'bonus_type',
	sourceAccount   : 'source_account', // string
	sourceAmount    : 'source_amount',
	bonusEligibility: 'bonus_eligibility', // boolean
	bonusPercentage : 'bonus_percentage',
	bonusAmount     : 'bonus_amount',
	totalBonus      : 'total_bonus',
	actionNumber    : 'action_number',

	depositType: 'deposit_type',

	amountFrom: 'amount_from',
	amountTo  : 'amount_to',
	dateFrom  : 'from',
	dateTo    : 'to',

	userName: 'user_name',
	created : 'created',
};

const listAdapter           = createListDataAdapter();

export function getListParams(filter: IInitialFilter, sorting, pagination = null) {

	const builder = new ParamsBuilder();
	const rules = builder.RULES;

	builder.addValue('sort_by', fields[sorting.sortBy]);
	builder.addValue('sort_order', sorting.sortOrder);

	if (pagination) {
		builder.addValue('limit', pagination.itemsPerPage);
		builder.addValue('page', pagination.currentPage);
		builder.addValue('include_user', true);
	}

	builder.addField(rules.isString, 'code', fields.code);
	builder.addField(rules.isString, 'externalReferenceNumber', fields.externalReferenceNumber);
	builder.addField(rules.isID, 'ID', fields.ID);
	builder.addField(rules.isNullOrNumber, 'amount', fields.amount);
	builder.addField(rules.isID, 'typeID', fields.typeID);
	builder.addField(rules.isID, 'statusID', fields.statusID);
	builder.addField(rules.isID, 'moneyTypeID', fields.moneyTypeID);
	builder.addField(rules.isID, 'currencyID', fields.currencyID);
	builder.addField(rules.isString, 'sourceTypeID', fields.sourceTypeID);
	builder.addField(rules.isString, 'sourceID', fields.sourceID);

	builder.addField(rules.isID, 'userID', fields.userID);
	builder.addField(rules.isID, 'sourceID', fields.sourceID);

	// builder.addField(rules.isID, 'websiteID', fields.websiteID);
	builder.addField(rules.isNumber, 'amountUSD', fields.amountUSD);

	builder.addRangeField(rules.isNumberRange, filter.amountRange, [fields.amountFrom, fields.amountTo]);
	builder.addRangeField(rules.isDateTimeRange, filter.dateRange, [fields.dateFrom, fields.dateTo]);

	const params = builder.biuldParams(filter);

	return params;
}

// Adapt ------------------------------------------------------------------------------------------

export function adaptUserAllTransactionList(rawData:ITransactionsData[]):IResTransactionAdapt {

	const list = listAdapter.adaptList(rawData);
	const entities = createEntities(rawData);

	return {
		// eslint-disable-next-line
		// @ts-ignore
		list,
		entities,
	};
}
// Adapters ---------------------------------------------------------------------------------------

function createListDataAdapter() {

	const adapter = new EntityAdapter();
	const rules = adapter.RULES;

	adapter.addField(rules.id, 'ID', fields.ID);
	adapter.addField(rules.id, 'typeID', fields.typeID);
	adapter.addField(rules.id, 'statusID', fields.statusID);
	adapter.addField(rules.id, 'userID', fields.userID);
	adapter.addField(rules.id, 'moneyTypeID', fields.moneyTypeID);
	adapter.addField(rules.id, 'currencyID', fields.currencyID);
	adapter.addField(rules.id, 'productID', fields.productID);
	adapter.addField(rules.id, 'channelID', fields.channelID);

	adapter.addField(rules.string, 'externalReferenceNumber', fields.externalReferenceNumber);
	adapter.addField(rules.string, 'ip', fields.ip);
	adapter.addField(rules.string, 'ipCountry', fields.ipCountry);
	adapter.addField(rules.string, 'code', fields.code);

	adapter.addField(rules.number, 'balanceBefore', fields.balanceBefore);
	adapter.addField(rules.number, 'balanceAfter', fields.balanceAfter);
	adapter.addField(rules.number, 'bonusType', fields.bonusType);
	adapter.addField(rules.number, 'bonusPercentage', fields.bonusPercentage);
	adapter.addField(rules.number, 'bonusAmount', fields.bonusAmount);
	adapter.addField(rules.number, 'totalBonus', fields.totalBonus);
	adapter.addField(rules.number, 'amount', fields.amount);
	adapter.addField(rules.number, 'amountUSD', fields.amountUSD);

	adapter.addField(rules.bool, 'bonusEligibility', fields.bonusEligibility);
	adapter.addField(rules.fullDate, 'created', fields.created);

	adapter.addField(rules.object, 'data', fields.data);
	adapter.addField(rules.string, 'userName', fields.userName);
  
	adapter.addField(rules.number, 'sourceID', fields.sourceID);
	adapter.addField(rules.number, 'sourceTypeID', fields.sourceTypeID);

	return adapter;
}
export function createReportParams(filter, sorting, reportFields, availableFields) {

	const params  = getListParams(filter, sorting);
	params.fields = getReportFields(reportFields, availableFields);

	return params;
}

export function getReportFields(reportFields, availableFields) {
	if (!isArray(reportFields) || isEmpty(reportFields)) {
		return availableFields.map(key => fields[key]);
	}

	const result = [];
	reportFields.forEach(field => {
		const { id, checked } = field;
		if (checked) {
			result.push(fields[id]);
		}
	});

	return result;
}
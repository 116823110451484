import { all, takeEvery, put, fork, call, select } from 'redux-saga/effects';

import actions from './actions';
import tableActions from '../../tables/actions';

import { getListParams, adaptTransactionList, availableFields, createReportParams } from './utils';

import { transactionsAPI } from '../../../helpers/api/transactions';
import { downloadReport, getHeadersTotalCount } from '../../../helpers/utils';
import { websitesAPI } from '../../../helpers/api/websites';
import { deriveTablePagination } from '../../../selectors/tables';
import { TABLE_TYPES } from '../../../constants/tableTypes';
import { logger } from '../../../helpers/logger';
import { showError } from '../../../helpers/notifications';


const prefix = 'reports.transactions';

const messages = {
	errorListReload       : `${prefix}.errorListReload`,
	errorSubscribeUpdate  : `${prefix}.errorSubscribeUpdate`,
	successSubscribeUpdate: `${prefix}.successSubscribeUpdate`,
	errorWebsiteListReload: `${prefix}.errorWebsiteListReload`,
	errorGetTable         : `${prefix}.errorGetTable`,
	errorPostTable        : `${prefix}.errorPostTable`,
};


const tableType = TABLE_TYPES.transactions;

function getStoreData(state) {
	const { Reports: { Transactions }, Tables } = state;

	return {
		filter      : Transactions.get('filter'),
		sorting     : Tables.get(tableType).sorting,
		pagination  : deriveTablePagination(tableType, state),
		reportFields: Transactions.get('reportFields'),
	};
}

function* listReload() {

	yield takeEvery(actions.TRANSACTIONS_LIST_RELOAD, function* () {

		yield put(actions.uiRefresh({ loading: true }));

		const { filter, sorting, pagination } = yield select(getStoreData);
		const params = getListParams(filter, sorting, pagination);
		let transactionIDs = [];
		let entities = {};
		let totalCount = 0;
		try {
			const res = yield call(transactionsAPI.transactionsList, params);
			if (res && res.status === 200) {
				const result = adaptTransactionList(res.data.data);
				transactionIDs = result.transactionIDs; // eslint-disable-line prefer-destructuring
				entities = result.entities;       // eslint-disable-line prefer-destructuring
				totalCount = getHeadersTotalCount(res.headers) || transactionIDs.length;
			}

		} catch (error) {
			showError(messages.errorListReload, error);
			logger.log(error);
		}
		yield put(actions.dataRefresh(transactionIDs, entities));
		yield put(tableActions.paginationRefresh(tableType, { totalCount }));
		yield put(actions.uiRefresh({ loading: false }));
		yield put(actions.subscribeToUpdate(transactionIDs));
	});
}

function* filterApply() {

	yield takeEvery(actions.TRANSACTIONS_FILTER_APPLY, function* () {
		yield put(actions.listReload());
	});
}

function* websiteListReload() {

	yield takeEvery(actions.TRANSACTIONS_WEBSITES_LIST_RELOAD, function* () {
		try {
			const res = yield call(websitesAPI.websitesList, {});
			if (res && res.status === 200) {
				yield put(actions.websitesListRefresh(res.data.data));
			}
		} catch (error) {
			showError(messages.errorListReload, error);
			logger.log(error);
		}

	});

}

function* reportDataReload() {

	yield takeEvery(actions.TRANSACTIONS_REPORT_DATA_RELOAD, function* () {

		yield put(actions.uiRefresh({ reportLoading: true }));
		yield put(actions.dataRefresh('')); // TODO check

		const { filter, sorting, reportFields } = yield select(getStoreData);
		const params = createReportParams(filter, sorting, reportFields, availableFields);

		try {
			const res = yield call(transactionsAPI.reportsListByCSV, params);
			if (res) {
				downloadReport(res);
			}

		} catch (error) {
			showError(messages.errorDataLoad);
			logger.log(error);
		}

		yield put(actions.uiRefresh({ reportLoading: false }));
	});
}

export default function* rtmCasinoSaga() {
	yield all([
		fork(listReload),
		fork(filterApply),
		fork(websiteListReload),
		fork(reportDataReload),
	]);
}
